import axios from "axios";
import axiosJsonpAdapter from "axios-jsonp";
import { EXTERNAL_API_URL, PROHIBITED_CHARACTERS_REGEX } from "@/const";
export default {
  data() {
    return {
      validate: [],
    };
  },
  methods: {
    getFormValidate(formId) {
      return this.validate.filter((v) => v.formId === formId)[0];
    },
    getFormValidateFactory(formId, factoryId) {
      return this.getFormValidate(formId).generateFactoryInfoList.filter((v) => v.factoryId === factoryId)[0];
    },
    getFormValidateFactoryStore(formId, factoryId, storeId) {
      return this.getFormValidateFactory(formId, factoryId).generateStoreInfoList.filter((v) => v.storeId === storeId)[0];
    },

    async check(generateCompanyInfoList) {
      let isValidForm = true;
      const numberRegexp = new RegExp(/^[0-9]*$/);
      const halfAlphaRegexp = new RegExp(/^[A-Za-z0-9]*$/);

      for (let i = 0; i < this.validate.length; i++) {
        const v = this.validate[i];
        const company = generateCompanyInfoList[i];

        // 事業者名の禁則文字のチェック
        if (PROHIBITED_CHARACTERS_REGEX.test(company.name)) {
          v.companyName.errMsg = "登録できない文字が含まれています。";
          isValidForm = false;
        }
        // 事業者名の必須と桁数チェック
        if (!v.companyName.isValid) {
          v.companyName.errMsg = "事業者名は必須入力です。";
          isValidForm = false;
        }
        if (company.name.length > 100) {
          v.companyName.errMsg = "事業者名は100桁以内で入力してください。";
          isValidForm = false;
        }
        // 郵便番号の必須と桁数、文字種(数値)チェック
        if (!v.zipCode.isValid) {
          v.zipCode.errMsg = "郵便番号は必須入力です。";
          isValidForm = false;
        } else {
          const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${company.addressInfo.zipCode}`;
          await axios
            .get(url, {adapter: axiosJsonpAdapter})
            .then((res) => {
              const results = res.data.results;
              if (results != null) {
                if (v.prefecture.isValid) {
                  var prefectureFlg = false;
                  results.forEach((address) => {
                    if (company.addressInfo.prefecturesName === address.address1) {
                      prefectureFlg = true;
                    }
                  });
                  if (!prefectureFlg) {
                    v.prefecture.errMsg = "郵便番号と都道府県が一致していません。";
                    isValidForm = false;
                  }
                }
                if (v.address.isValid) {
                  var addressFlg = false;
                  results.forEach((address) => {
                    if (company.addressInfo.address === address.address2) {
                      addressFlg = true;
                    }
                  });
                  if (!addressFlg) {
                    v.address.errMsg = "郵便番号と市区町村が一致していません。";
                    isValidForm = false;
                  }
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (company.addressInfo.zipCode.length > 7) {
          v.zipCode.errMsg = "郵便番号は7桁以内で入力してください。";
          isValidForm = false;
        }
        if (!numberRegexp.test(company.addressInfo.zipCode)) {
          v.zipCode.errMsg = "郵便番号は数字で入力してください。";
          isValidForm = false;
        }
        // 都道府県の必須チェック
        if (!v.prefecture.isValid) {
          v.prefecture.errMsg = "都道府県は必須です。";
          isValidForm = false;
        }
        // 市区町村番地の必須と桁数チェック
        if (!v.address.isValid) {
          v.address.errMsg = "市区町村番地は必須入力です。";
          isValidForm = false;
        }
        if (company.addressInfo.address.length > 100) {
          v.address.errMsg = "市区町村番地は100桁以内で入力してください。";
          isValidForm = false;
        }
        // 電話番号の必須と桁数、文字種(数値)チェック
        if (!v.tel.isValid) {
          v.tel.errMsg = "電話番号は必須入力です。";
          isValidForm = false;
        }
        if (company.tel.length > 15) {
          v.tel.errMsg = "電話番号は15桁以内で入力してください。";
          isValidForm = false;
        }
        if (!numberRegexp.test(company.tel)) {
          v.tel.errMsg = "電話番号は数字で入力してください。";
          isValidForm = false;
        }
        // FAX番号の桁数と文字種(数値)チェック
        if (company.fax && company.fax.length > 15) {
          v.fax.errMsg = "FAX番号は15桁以内で入力してください。";
          isValidForm = false;
        }
        if (company.fax && !numberRegexp.test(company.fax)) {
          v.fax.errMsg = "FAX番号は数字で入力してください。";
          isValidForm = false;
        }
        // JWNET加入者番号の必須と文字種(数値)、桁数チェック
        if (!v.jwnetContractNo.isValid) {
          v.jwnetContractNo.errMsg = "JWNET加入者番号は必須入力です。";
          isValidForm = false;
        }
        if (!halfAlphaRegexp.test(company.jwnetContractNo)) {
          v.jwnetContractNo.errMsg = "JWNET加入者番号は半角英数字で入力してください。";
          isValidForm = false;
        }
        if (company.jwnetContractNo.length > 7) {
          v.jwnetContractNo.errMsg = "JWNET加入者番号は7桁以内で入力してください。";
          isValidForm = false;
        }
        // EDI利用確認キーの必須と文字種(数値)、桁数チェック
        if (!v.ediKey.isValid) {
          v.ediKey.errMsg = "EDI利用確認キーは必須入力です。";
          isValidForm = false;
        }
        if (!halfAlphaRegexp.test(company.ediKey)) {
          v.ediKey.errMsg = "EDI利用確認キーは半角英数字で入力してください。";
          isValidForm = false;
        }
        if (company.ediKey.length > 8) {
          v.ediKey.errMsg = "EDI利用確認キーは8桁以内で入力してください。";
          isValidForm = false;
        }

        // 事業所チェック
        for (let j = 0; j < v.generateFactoryInfoList.length; j++) {
          const vfactory = v.generateFactoryInfoList[j];
          const factory = company.generateFactoryInfoList[j];

          // 事業所名の必須と桁数チェック
          if (!vfactory.name.isValid) {
            vfactory.name.errMsg = "事業所名は必須入力です。";
            isValidForm = false;
          }
          if (factory.name.length > 100) {
            vfactory.name.errMsg = "事業所名は100桁以内で入力してください。";
            isValidForm = false;
          }

          // 事業場チェック
          for (let k = 0; k < vfactory.generateStoreInfoList.length; k++) {
            const vstore = vfactory.generateStoreInfoList[k];
            const store = factory.generateStoreInfoList[k];

            // 事業場名の禁則文字のチェック
            if (PROHIBITED_CHARACTERS_REGEX.test(store.name)) {
              vstore.name.errMsg = "登録できない文字が含まれています。";
              isValidForm = false;
            }

            // 事業場名の必須と桁数チェック
            if (!vstore.name.isValid) {
              vstore.name.errMsg = "事業場名は必須入力です。";
              isValidForm = false;
            }
            if (store.name.length > 100) {
              vstore.name.errMsg = "事業場名は100桁以内で入力してください";
              isValidForm = false;
            }
            // 郵便番号の必須と桁数、文字種(数値)チェック
            if (!vstore.zipCode.isValid) {
              vstore.zipCode.errMsg = "郵便番号は必須入力です。";
              isValidForm = false;
            } else {
              const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${store.addressInfo.zipCode}`;
              await axios
                .get(url, {adapter: axiosJsonpAdapter})
                .then((res) => {
                  const results = res.data.results;
                  if (results != null) {
                    if (vstore.prefecture.isValid) {
                      var prefectureFlg = false;
                      results.forEach((address) => {
                        if (store.addressInfo.prefecturesName === address.address1) {
                          prefectureFlg = true;
                        }
                      });
                      if (!prefectureFlg) {
                        vstore.prefecture.errMsg = "郵便番号と都道府県が一致していません。";
                        isValidForm = false;
                      }
                    }
                    if (vstore.address.isValid) {
                      var addressFlg = false;
                      results.forEach((address) => {
                        if (store.addressInfo.address === address.address2) {
                          addressFlg = true;
                        }
                      });
                      if (!addressFlg) {
                        vstore.address.errMsg = "郵便番号と市区町村が一致していません。";
                        isValidForm = false;
                      }
                    }
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            if (!numberRegexp.test(store.addressInfo.zipCode)) {
              vstore.zipCode.errMsg = "郵便番号は数字で入力してください。";
              isValidForm = false;
            }
            if (store.addressInfo.zipCode.length > 7) {
              vstore.zipCode.errMsg = "郵便番号は7桁以内で入力してください。";
              isValidForm = false;
            }
            // 都道府県の必須チェック
            if (!vstore.prefecture.isValid) {
              vstore.prefecture.errMsg = "都道府県は必須入力です。";
              isValidForm = false;
            }
            // 市区町村番地の必須と桁数チェック
            if (!vstore.address.isValid) {
              vstore.address.errMsg = "市区町村番地は必須入力です。";
              isValidForm = false;
            }
            if (store.addressInfo.address.length > 100) {
              vstore.address.errMsg = "市区町村番地は100桁以内で入力してください。";
              isValidForm = false;
            }
            // 事業場電話番号の必須と文字種(数値)、桁数チェック
            if (!vstore.tel.isValid) {
              vstore.tel.errMsg = "事業場電話番号は必須入力です。";
              isValidForm = false;
            }
            if (!numberRegexp.test(store.tel)) {
              vstore.tel.errMsg = "事業場電話番号は数字で入力してください。";
              isValidForm = false;
            }
            if (store.tel.length > 15) {
              vstore.tel.errMsg = "事業場電話番号は15桁以内で入力してください。";
              isValidForm = false;
            }
            // 事業場FAX番号の桁数と文字種(数値)チェック
            if (store.fax && store.fax.length > 15) {
              vstore.fax.errMsg = "事業場FAX番号は15桁以内で入力してください。";
              isValidForm = false;
            }
            if (store.fax && !numberRegexp.test(store.fax)) {
              vstore.fax.errMsg = "事業場FAX番号は数字で入力してください。";
              isValidForm = false;
            }
            if (store.autoEmptyReservePossessNumb && (!numberRegexp.test(store.autoEmptyReservePossessNumb) ||
                store.autoEmptyReservePossessNumb < 0 || store.autoEmptyReservePossessNumb > 100)) {
              vstore.autoEmptyReservePossessNumb.errMsg = "半角で数値は0～100を入力してください";
              isValidForm = false;
            }
          }
        }
      }

      return isValidForm;
    },

    async runValidate(generateCompanyInfoList) {
      let isValidForm = false;
      isValidForm = await this.check(generateCompanyInfoList);

      return isValidForm;
    },
  },
};
